import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CollectionPage from '~/components/Collection/Page'
import ContentPage from '~/components/Content/Page'

const Shop = ({ data }) => {
  const { collection, page } = data

  return (
    <Layout>
      <Metadata />
      <CollectionPage collection={collection} />
      <ContentPage page={page} />
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopPage($locale: String) {
    collection: contentfulCollection(
      slug: { eq: "shop-wines" }
      node_locale: { eq: $locale }
    ) {
      ...CollectionDetailsFragment
    }
    page: contentfulPage(slug: { eq: "shop" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`
